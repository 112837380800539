<!-- =========================================================================================
    File Name: LockScreen.vue
    Description: Lock Screen Page
    ----------------------------------------------------------------------------------------
    Item Name: Legal Board Services
      Author: Enkompass
    Author URL: https://www.enkompass.net
========================================================================================== -->


<template>
    <div class="h-screen flex w-full bg-img vx-row no-gutter justify-center items-center">
        <div class="vx-col sm:w-3/5 md:w-3/5 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
            <vx-card>
                <div slot="no-body" class="full-page-bg-color">
                    <div class="vx-row no-gutter">
                        <div class="vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center">
                            <img src="@/assets/images/pages/lock-screen.png" alt="login" class="mx-auto">
                        </div>
                        <div class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center d-theme-dark-bg">
                            <div class="p-8">
                                <div class="vx-card__title mb-8">
                                    <h4 class="mb-4">Welcome to Legal Board Services!</h4>
                                    <p class="mb-4">Create your law firm to continue.</p>
                                </div>
                                <vs-input
                                    v-validate="'required'"
                                    data-vv-validate-on="blur"
                                    name="firmName"
                                    icon-no-border
                                    label-placeholder="Law Firm Name"
                                    v-model="firmName"
                                    class="w-full"/>
                                <span class="text-danger text-sm">{{ errors.first('firmName') }}</span>

                                <vs-input icon-pack="feather"
                                label-placeholder="Encryption Key (Optional)"
                                v-model="encryptionKey"
                                class="w-full mb-6" />

                                <div class="flex justify-between flex-wrap">
                                  <vs-button :disabled="!validateForm" @click="createFirm">Submit</vs-button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </vx-card>
        </div>
    </div>
</template>

<script>
import moduleTodo from '@/store/todo/moduleTodo.js'
import router from '@/router'

export default {
  data () {
    return {
      firmName: '',
      encryptionKey: ''
    }
  },
  computed: {
    validateForm () {
      return !this.errors.any() && this.firmName !== ''
    }
  },
  created () {
    if (!this.$store.hasModule("todo")) {
        this.$store.registerModule('todo', moduleTodo)
    }

    if(this.$store.state.auth.failedFirmCheckPoint() == false){
      router.push(router.currentRoute.query.to || '/').catch(err => {})
    }
  },
  methods: {
    createFirm() {

      // Loading
      this.$vs.loading()

      const payload = {
        name: this.firmName,
        encryptionKey: this.encryptionKey
      }

      this.$store.commit('todo/UPDATE_DATA_TYPE', 'accounts');

      this.$store.dispatch('todo/addData', payload)
        .then(response => {
          let userInfo = JSON.parse(localStorage.getItem("userInfo"));
          userInfo.setUpFirm = false;
          localStorage.setItem('userInfo', JSON.stringify(userInfo));

          router.push('/').catch(() => {})

          this.$vs.notify({
            title: 'Success',
            text: 'Law Firm successfully created',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'success'
          })

          this.$vs.loading.close()
        })
        .catch(err => {
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'Error',
            text: 'Law Firm creation failed.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        });
    }
  }
}
</script>
